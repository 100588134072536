const COUNTRIES_WITH_PROVIDER = [
    {
        provider: 'scopesi',
        countries: ['AR'],
    },
    {
        provider: 'onemata',
        countries: ['ZA', 'CL', 'MX', 'CO'],
    },
    {
        provider: 'cinnecta',
        countries: ['BR'],
    },
];

const COUNTRIES_OF_PROVIDERS = [
    {
        provider: ['scopesi', 'onemata'],
        countries: ['AR', 'PY'],
    },
    {
        provider: ['onemata'],
        countries: [
            'CL',
            'CO',
            'MX',
            'CR',
            'PA',
            'SV',
            'GT',
            'HN',
            'EC',
            'DO',
            'ES',
            'BR',
            'UY',
            'PE',
        ],
    },
    {
        provider: ['scopesi'],
        countries: ['PE'],
    },
    {
        provider: ['cinnecta'],
        countries: ['BR'],
    },
];

const COUNTRIES_WITH_TIMEZONES = [
    {
        value: 'TR',
        label: 'Turkey',
        timezone: +3,
    },
    {
        value: 'MX',
        label: 'Mexico',
        timezone: -6,
    },
    {
        value: 'MY',
        label: 'Malaysia',
        timezone: +8,
    },
    {
        value: 'AR',
        label: 'Argentina',
        timezone: -3,
    },
    {
        value: 'UY',
        label: 'Uruguay',
        timezone: -3,
    },
    {
        value: 'PY',
        label: 'Paraguay',
        timezone: -3,
    },
    {
        value: 'ES',
        label: 'Spain',
        timezone: +1,
    },
    {
        value: 'PT',
        label: 'Portugal',
        timezone: 0,
    },
    {
        value: 'MX',
        label: 'Mexico',
        timezone: -7,
    },
    {
        value: 'PE',
        label: 'Peru',
        timezone: -5,
    },
    {
        value: 'CL',
        label: 'Chile',
        timezone: -5,
    },
    {
        value: 'IN',
        label: 'India',
        timezone: +5.3,
    },
    {
        value: 'BR',
        label: 'Brazil',
        timezone: -3,
    },
    {
        value: 'ZA',
        label: 'South Africa',
        timezone: +2,
    },
    {
        value: 'CO',
        label: 'Colombia',
        timezone: -5,
    },
];

const COUNTRY_CURRENCY_NAME = {
    USD: 'US',
    ARS: 'AR',
    EUR: 'EU',
    MXN: 'MX',
    CLP: 'CL',
    INR: 'IN',
    PEN: 'PE',
    BRL: 'BR',
    ZAR: 'ZA',
    PYG: 'PY',
    COP: 'CO',
};

const CAMPAIGN_PROPOSALS_STATUSES = ['draft', 'archived', 'sent'];

module.exports = {
    COUNTRIES_WITH_PROVIDER,
    COUNTRIES_WITH_TIMEZONES,
    COUNTRY_CURRENCY_NAME,
    COUNTRIES_OF_PROVIDERS,
    CAMPAIGN_PROPOSALS_STATUSES,
};
